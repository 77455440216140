import classNames from 'classnames';
import Image from 'next/legacy/image';
import type { FunctionComponent } from 'react';
import { useMemo, useRef } from 'react';
import type { HeadingTag } from '../../components/heading';
import { Container } from '../../components/layout/grid';
import { Markdown } from '../../components/markdown';
import { Text } from '../../components/text';
import { Video } from '../../components/video';
import type { HeroV2Fragment } from '../../graphql/queries/GetHeroV2.graphql';
import { useDivDimensions } from '../../lib/hooks/useDivDimensions';
import { usePersonalizations } from '../../lib/hooks/usePersonalizations';
import { useWindowDimensions } from '../../lib/hooks/useWindowDimensions';
import { renderTemplate } from '../../lib/utils/text';
import variables from '../../styles/variables.module.scss';
import type { VideoPresetType, VideoSourceType } from '../../types/video';
import { ActionButton } from '../action-button';
import { DynamicElementComponent } from '../dynamic-element';
import styles from './hero-v2.module.scss';

/**
 * Displays a content block with a title, text, image,and/or link button next to some sort of media, such as a video or image, and options for background color, image or video.
 */

export const HeroV2: FunctionComponent<Props> = ({
  layout = 'Copy Left Media Right',
  isCarousel = false,
  showWinnersBadge = false,
  heading,
  headingColor = 'white',
  backgroundType = 'color',
  backgroundColor = 'black',
  bgVideo,
  backgroundVideoFit = 'cover',
  backgroundVideoMobile,
  backgroundImageDesktop,
  backgroundImageMobile,
  copy,
  copyColor = 'white',
  supportingCopy,
  supportingCopyColor = 'white',
  copyContainerPadding = 'gapXLarge',
  button,
  secondaryButton,
  image,
  video,
  headingTag = 'h1',
  dynamicElement,
  imageAlignment = 'center',
  mediaVisible = 'visible',
  mediaType = 'image',
  hasBottomMargin = false
}) => {
  const mobileBreakpoint = 768;
  const personalizations = usePersonalizations();
  const {
    width
  } = useWindowDimensions();
  const containerRef = useRef(null);
  const {
    divHeight
  } = useDivDimensions(containerRef);
  const visualElement = useMemo(() => {
    if (dynamicElement && mediaType === 'animation') {
      return <DynamicElementComponent type={dynamicElement} />;
    }
    if (video && mediaType === 'video') {
      return <Video {...video} preset={video.preset as VideoPresetType ?? 'gif'} poster={video.poster?.source} alt={video.alt as string} sources={video?.files?.items as VideoSourceType[]} className={styles.media} style={{
        visibility: mediaVisible === 'visible' ? 'visible' : 'hidden'
      }} />;
    }
    if (image?.url && mediaType === 'image') {
      return <Image priority className={styles.media} src={image.url} layout='responsive' width={image.width} height={image.height} alt={image?.alt ?? ''} style={{
        visibility: mediaVisible === 'visible' ? 'visible' : 'hidden'
      }} />;
    }
    return null;
  }, [dynamicElement, mediaType, video, image?.url, image?.width, image?.height, image?.alt, mediaVisible]);
  function getBackgroundStyle() {
    if (backgroundType === 'video') return;
    if (backgroundType === 'image' && width > mobileBreakpoint) {
      return {
        backgroundImage: getBackgroundImageUrl()
      };
    }
    if (backgroundColor === 'pinkPurpleGradient') {
      return {
        background: variables[backgroundColor]
      };
    }
    return {
      backgroundColor: variables[backgroundColor]
    };
  }
  function getBackgroundImageUrl() {
    let bgImageUrl = `url(${backgroundImageDesktop?.url})`;
    if (width < mobileBreakpoint && backgroundImageMobile?.url) {
      bgImageUrl = `url(${backgroundImageMobile?.url})`;
    }
    return bgImageUrl;
  }
  function getBgDimensions() {
    return {
      width: width,
      height: divHeight
    };
  }
  return <div className={`${styles.backgroundContainer} ${hasBottomMargin ? styles.hasBottomMargin : ''} ${styles[backgroundVideoFit]}`} style={getBackgroundStyle()} ref={containerRef} data-sentry-component="HeroV2" data-sentry-source-file="hero-v2.tsx">
            {width > mobileBreakpoint && bgVideo && backgroundType === 'video' && <div className={classNames(styles.videoContainer)} style={getBgDimensions()}>
                        <video className={`${styles.bgVideo} ${styles[backgroundVideoFit]}`} autoPlay muted playsInline webkit-playsinline='true' loop={bgVideo.loop} poster={bgVideo.poster?.source}>
                            <source src={bgVideo?.files?.items[0].source} type='video/mp4' />
                        </video>
                    </div>}

            {/* Content Container */}
            <Container className={`${styles.container} ${hasBottomMargin ? styles.hasBottomMargin : ''}`} data-sentry-element="Container" data-sentry-source-file="hero-v2.tsx">
                {showWinnersBadge && <div className={styles.winnersBadge}>
                        <Image className={styles.badge} src='https://images.ctfassets.net/bkmkssdu4exw/4qAdUBJ27n55bCTEzK8c2J/4131a3bb439abec1554b6440916b9f06/winners-badge-o.png' width={200} height={200} alt='Winners Badge' />
                    </div>}

                <div className={`${styles.contentContainer} ${isCarousel ? styles.isCarousel : ''}`} style={{
        alignItems: imageAlignment === 'center' ? 'center' : 'flex-end',
        justifyContent: mediaVisible === 'center' ? 'center' : 'left',
        flexDirection: layout === 'Copy Right Media Left' ? 'row-reverse' : 'row'
      }}>
                    <div className={`${styles.copyContainer} ${isCarousel ? styles.isCarousel : ''} ${styles[copyContainerPadding]}`} style={{
          width: layout === 'Copy Centered No Media' ? '100%' : '50%',
          textAlign: layout === 'Copy Centered No Media' ? 'center' : 'left'
        }}>
                        <div className={classNames(styles[`column-inner`])}>
                            {/* Heading */}
                            {width > mobileBreakpoint && <Markdown tag={headingTag} typeStyle={{
              _: 'heading-sm',
              md: 'heading-xl'
            }} markdown={renderTemplate(heading?.toUpperCase(), personalizations)} color={headingColor} accentColor='primary' />}

                            {width < mobileBreakpoint && <Markdown tag={headingTag} typeStyle={{
              _: 'heading-sm',
              md: 'heading-xl'
            }} markdown={renderTemplate(heading, personalizations)} color='white' accentColor='primary' />}

                            {/* Copy */}
                            <div className={styles['copy-container']}>
                                {width > mobileBreakpoint && <Markdown typeStyle={{
                _: 'body-lg',
                md: 'body-md'
              }} markdown={renderTemplate(copy, personalizations)} color={copyColor} />}

                                {width < mobileBreakpoint && <Markdown typeStyle={{
                _: 'body-lg',
                md: 'body-md'
              }} markdown={renderTemplate(copy, personalizations)} color='white' />}
                            </div>

                            <div className={`${styles.buttons} ${layout === 'Copy Centered No Media' ? styles.buttonsCentered : ''}`}>
                                {!!button && <ActionButton preset={button?.preset} {...button} />}
                                {!!secondaryButton && <ActionButton preset={secondaryButton?.preset} {...secondaryButton} />}
                            </div>

                            {/* Supporting Copy */}
                            {supportingCopy && <Text typeStyle={{
              _: 'body-lg'
            }} tag='div' className={styles['supporting-copy']} color={supportingCopyColor}>
                                    {supportingCopy}
                                </Text>}
                        </div>
                    </div>

                    <div className={`${styles.imageContainer} ${isCarousel ? styles.isCarousel : ''}`} style={{
          paddingBottom: width < mobileBreakpoint && imageAlignment === 'center' ? '64px' : '0',
          display: layout === 'Copy Centered No Media' ? 'none' : 'flex'
        }}>
                        <div className={classNames(styles[`column-inner`])}>
                            {visualElement}
                        </div>
                    </div>
                </div>
            </Container>

            {width < mobileBreakpoint && backgroundVideoMobile && backgroundType === 'video' && <div className={styles.mobileBackgroundVideoContainer}>
                        <video className={styles.bgVideo} autoPlay muted playsInline webkit-playsinline='true' loop={backgroundVideoMobile?.loop} poster={backgroundVideoMobile?.poster?.source}>
                            <source src={backgroundVideoMobile?.files?.items?.[0]?.source} type='video/mp4' />
                        </video>
                    </div>}

            {width < mobileBreakpoint && backgroundImageMobile && backgroundImageMobile.url && backgroundType === 'image' && <div className={styles.mobileBackgroundImageContainer}>
                        <Image priority className={styles.media} src={backgroundImageMobile.url} layout='responsive' width={backgroundImageMobile.width} height={backgroundImageMobile.height} alt={backgroundImageMobile?.alt ?? ''} style={{
        visibility: mediaVisible === 'visible' ? 'visible' : 'hidden'
      }} />
                    </div>}
        </div>;
};
export default HeroV2;
type Props = Omit<HeroV2Fragment, 'sys' | 'type'> & {
  index?: number;
  headingTag?: HeadingTag;
  isCarousel?: boolean;
};